<template>
  <form v-if="show" v-auto-animate class="flex w-full flex-col" @submit.prevent="createNewUser">
    <fieldset v-if="showCreateUser || show" class="flex w-full flex-col gap-6">
      <InputGroup>
        <InputGroupAddon>
          <i class="uil-user"></i>
        </InputGroupAddon>
        <InputText
          v-model="createUserForm.name"
          autofocus
          placeholder="Nome do usuário"
          @input="nameValidation(createUserForm.name)"
        />
      </InputGroup>

      <InputGroup>
        <InputGroupAddon>
          <i class="uil-envelope"></i>
        </InputGroupAddon>
        <InputText
          v-model="createUserForm.email"
          placeholder=" Ex. email@email.com"
          @input="emailValidation(createUserForm.email)"
        />
      </InputGroup>
      <Button type="submit" label="Criar usuário" :disabled="!isFormValid" :loading="loadingUserCreation" />
    </fieldset>

    <Message v-if="createUserFeedback.hasFeedback" :severity="createUserFeedback.status">
      {{ createUserFeedback.message }}
    </Message>
  </form>
</template>

<script setup>
import { computed, ref, watch } from "vue";

import { registerIncompleteUser } from "@/services/user";
import validateEmail from "@/utils/validateEmail";
import validateName from "@/utils/validateName";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const showCreateUser = ref(false);

const nameIsValid = ref(false);
const emailIsValid = ref(false);
const loadingUserCreation = ref(false);

const createUserForm = ref({
  name: "",
  email: "",
});
const createUserFeedback = ref({
  hasFeedback: false,
  status: "",
  message: "",
});

const isFormValid = computed(() => {
  return nameIsValid.value && emailIsValid.value;
});

const nameValidation = (event) => {
  nameIsValid.value = validateName(event);
};

const emailValidation = (event) => {
  emailIsValid.value = validateEmail(event);
};

const createNewUser = async () => {
  loadingUserCreation.value = true;
  await registerIncompleteUser(createUserForm.value)
    .then((res) => {
      createUserFeedback.value.hasFeedback = true;
      createUserFeedback.value.status = "success";
      createUserFeedback.value.message = "Usuário criado com sucesso!";
      createUserForm.value.name = "";
      createUserForm.value.email = "";
      nameIsValid.value = false;
      emailIsValid.value = false;
      showCreateUser.value = false;
    })
    .catch((error) => {
      console.error(error);

      createUserFeedback.value.hasFeedback = true;
      createUserFeedback.value.status = "error";
      createUserFeedback.value.message = "Não foi possível criar o usuário.";
      showCreateUser.value = false;

      setTimeout(() => {
        createUserFeedback.value.hasFeedback = false;
      }, 5000);
    });
  loadingUserCreation.value = false;
};

watch(
  props.show,
  (newShow) => {
    !newShow ? (createUserFeedback.value.hasFeedback = false) : null;
  },
  { immediate: true },
  { deep: true },
);
</script>

<style lang="scss" scoped>
:deep(.p-message-close) {
  margin-left: auto;
}
</style>
