<template>
  <div class="h-full relative">
    <div class="flex flex-col lg:px-16">
      <div class="flex flex-col justify-center">
        <div class="flex justify-between mt-12">
          <Skeleton width="200px" height="42px" class="mb-5 rounded" />

          <Skeleton width="38px" height="38px" class="mb-5 rounded" />
        </div>

        <div class="hidden lg:block">
          <div class="flex gap-5">
            <Skeleton width="136px" height="113px" class="mb-2 rounded" />
            <Skeleton width="136px" height="113px" class="mb-2 rounded" />
            <Skeleton width="200px" height="113px" class="mb-2 rounded" />
          </div>
        </div>
        <div class="block lg:hidden">
          <div class="flex gap-5">
            <Skeleton width="72px" height="72px" class="mb-2 rounded" />
            <Skeleton width="72px" height="72px" class="mb-2 rounded" />
            <Skeleton width="120px" height="72px" class="mb-2 rounded" />
          </div>
        </div>

        <div class="w-full mt-10 flex justify-between">
          <div class="w-full flex justify-between">
            <Skeleton width="142px" height="42px" class="mb-5 rounded" />
            <Skeleton width="142px" height="42px" class="mb-5 ml-8 rounded" />
          </div>

          <Skeleton width="142px" height="42px" class="mb-5 ml-8 rounded" />
        </div>
      </div>
      <div class="w-full h-full w-1/2">
        <div class="flex w-full justify-between pt-8">
          <Skeleton width="110px" height="40px" class="mb-2 rounded" />
          <Skeleton width="110px" height="40px" class="mb-2 rounded" />
        </div>
        <div class="mt-8 flex items-center rounded">
          <Skeleton height="100px" class="w-full mb-2 rounded" />
        </div>
        <div class="mt-4 flex items-center rounded">
          <Skeleton height="100px" class="w-full mb-2 rounded" />
        </div>
        <div class="mt-4 flex items-center rounded">
          <Skeleton height="100px" class="w-full mb-2 rounded" />
        </div>
        <div class="mt-4 flex items-center rounded">
          <Skeleton height="100px" class="w-full mb-2 rounded" />
        </div>
        <div class="mt-4 flex items-center rounded">
          <Skeleton height="100px" class="w-full mb-2 rounded" />
        </div>
      </div>
    </div>
  </div>
</template>
