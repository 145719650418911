<template>
  <template v-if="photo">
    <img class="h-[18px] w-[18px] rounded-md bg-cover" :src="photo" :alt="`Foto do ${name}`" />
  </template>
  <template v-else>
    <Avatar v-if="name" :label="name.charAt(0)" />
  </template>
</template>

<script setup>
const props = defineProps({
  photo: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});
</script>

<style lang="scss" scoped></style>
