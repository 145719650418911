<template>
  <div v-if="notifications.length > 0" class="relative">
    <Button
      :badge="1"
      :badgeClass="badgeClass"
      outlined
      class="without-border flex h-12 !min-h-fit w-12 items-center justify-center focus:!shadow-none lg:min-h-[50px]"
      type="button"
      rounded
      aria-haspopup="true"
      icon="uil uil-bell text-neutral-low-dark"
      aria-controls="overlay_notify"
      @click="toggle"
    />
    <TieredMenu
      id="overlay_notify"
      ref="menu_notifications"
      :appendTo="isMobile ? 'body' : 'self'"
      class="relative !w-full min-w-[264px] overflow-y-auto"
      :model="notifications"
      popup
    >
      <template #start> <h4 class="absolute top-0 z-1 w-full bg-white px-4 py-3 font-bold">Notificações</h4> </template>
      <template #item="{ item }">
        <Notification :notification="item" @notification-removed="notificationRemoved" />
      </template>
    </TieredMenu>
  </div>
</template>

<script setup>
import moment from "moment";
import { useToast } from "primevue/usetoast";
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";

import { useIsMobile } from "@/hooks";
import { getNotifications } from "@/services/notifications";

import Notification from "./components/Notification.vue";

const toast = useToast();

const { isMobile } = useIsMobile();

const menu_notifications = ref();
const notifications = ref([]);
const summary = ref();
const badgeClass = ref();

const toggle = (event) => {
  menu_notifications.value.toggle(event);
};

const handleGetNotifications = async () => {
  try {
    await getNotifications("?has_pagination=1").then((res) => {
      const response = res.data;
      if (res.data) {
        notifications.value = response.notifications;
        notifications.value.map((item) => {
          item.updated_at = moment(item.updated_at).format("DD/MM/YYYY");
        });
        summary.value = res.data.summary;
        badge();
      }
      return;
    });
  } catch (error) {
    console.error(error);
  }
};

const badge = () => {
  badgeClass.value =
    summary.value.unread === 0
      ? "custom-badge !text-transparent opacity-0"
      : "custom-badge !text-transparent opacity-100";
};

const notificationRemoved = () => {
  toast.add({
    severity: "success",
    summary: "Notificação removida com sucesso!",
    life: 3000,
  });
  handleGetNotifications();
};

const handleScroll = () => {
  if (menu_notifications.value && menu_notifications.value.visible) {
    menu_notifications.value.hide();
  }
};

onBeforeMount(() => {
  handleGetNotifications();
});

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="scss" scoped>
@import "@/styles/themes/tailwind/tailwind-light/_variables.scss";

:deep(#overlay_notify) {
  top: 38px !important;
  right: 0 !important;
  left: auto !important;

  max-height: 300px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

:deep(.p-button) {
  position: relative;
  overflow: visible;

  .p-badge.custom-badge {
    position: absolute;
    top: -4px;
    right: -4px;
    order: 1;
    width: 10px !important;
    vertical-align: middle;
    color: #fff;
    font-weight: normal;
    @apply bg-status-positive-light;
  }

  .p-button-icon {
    position: absolute;
  }
}
:deep(.p-tieredmenu-root-list) {
  @apply pt-12;
}
.without-border {
  border: none !important;
}
</style>
